import * as React from "react";

import { ConfiguredLink, getLinkFromLinkingSpec } from "../../../utils/linking.util";
import { FormSpec, localized, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { MxtsApi, MxtsApiWrapper, QuestionAnswer, QuestionnaireCategory } from "@maxxton/cms-mxts-api";
import { RESORT, contentTypeSelector, descriptionTypeMultiSelector, resortAutocomplete } from "../../../components/utils";
import { resourcesAutocomplete, unitsAutocomplete } from "../../../inputSpecs";

import { DynamicSingleReviewRating } from "./SingleReviewRating";
import { LocaleApi } from "@maxxton/cms-api";
import { LocalizedTitleOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { PageWidgetSpec } from "../../widget";
import { SelectOption } from "../../../form-specs/formSpec.types";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getAdminMxtsEnv } from "../../mxts";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

export interface WidgetOptions extends LocalizedTitleOptions {
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    descriptionTypes?: NumberMultiSelectOption[];
    showRating?: boolean;
    showReview?: boolean;
    styleIds?: StyleOptions[];
    outOfFiveRating?: boolean;
    localizedContent: LocaleContent[];
    minRating?: number;
    showNumberOfReview?: boolean;
    showInGoogleAggregation?: boolean;
    showReplyFromResort?: boolean;
    resortSelector?: number;
    descFontSize: string;
    descFontColor: string;
    ratingIconColor: string;
    ratingIconSize?: number;
    reviewStyle: boolean;
    ratingStyle: boolean;
    linking?: LinkingSpecOptions;
    webContentId: string | null;
    templateId?: string | null;
    showNotEnoughRatingsText?: boolean;
    notEnoughRatingsText?: string | null;
    lessReviewWebContentId?: string | null;
    lessReviewTemplateId?: string | null;
    displayLessReviewInfo?: string;
    hideTexts?: boolean;
}

interface StyleOptions {
    value: string | null;
    text: string;
}
interface LocaleContent {
    minRatingForReview?: number;
    locale: string;
    categoryId?: number;
    commentId?: number;
}

const TARGETS = ["single-review-rating"];

async function getQuestionnaireCategories(mxtsApi: MxtsApiWrapper): Promise<Array<SelectOption<number>>> {
    const ops = await getAdminMxtsEnv();
    const categories = await mxtsApi.questionnaireCategories(ops, { size: 999 }).then((res) => res.content);
    return categories.map(
        (cat: QuestionnaireCategory): SelectOption<number> => ({
            value: cat.questionCategoryId,
            label: cat.name,
        })
    );
}

async function getComments(mxtsApi: MxtsApiWrapper, rootItem: WidgetOptions, tabLocale?: string): Promise<Array<SelectOption<number>>> {
    const currentLocallized = rootItem.localizedContent.find((localizedContent) => localizedContent.locale === tabLocale);
    if (currentLocallized && currentLocallized.categoryId) {
        const langData = await LocaleApi.findById({ id: currentLocallized.locale });
        const langCode = langData ? langData.code : undefined;
        const ops = await getAdminMxtsEnv();
        const allLanguageData = await mxtsApi.languages(ops, { shortName: langCode });
        const languageId = allLanguageData ? (allLanguageData as any).languageId : undefined;
        const answers = await mxtsApi
            .questionAnswers(ops, {
                size: 50,
                questionCategoryId: currentLocallized.categoryId,
                onlyWithComment: true,
                minRatingAnswer: currentLocallized.minRatingForReview || 7,
                sort: "questionnaireAnswerId,DESC",
                languageId,
            })
            .then((res) => res.content);
        return answers.map(
            (answer: QuestionAnswer): SelectOption<number> => ({
                value: answer.questionnaireAnswerId,
                label: answer.comments,
            })
        );
    }
    return [];
}

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-single-review-rating",
    name: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleReviewRating"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleReviewRatings"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            contentTypeSelector("contentType"),
                            { ...resortAutocomplete("resortSelector"), visible: (options: WidgetOptions): boolean => options.contentType === RESORT },
                            resourcesAutocomplete("resourceId"),
                            unitsAutocomplete("unitId"),
                            descriptionTypeMultiSelector("descriptionTypes", undefined, false),
                            {
                                variable: "showReview",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showReview"),
                                default: false,
                                type: "checkbox",
                                groupName: "reviewRatingGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "reviewRatingGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "reviewRatingGroupDescription"),
                            },
                            {
                                variable: "showRating",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showRating"),
                                default: false,
                                type: "checkbox",
                                groupName: "reviewRatingGroup",
                            },
                        ],
                    ],
                },
                { name: getI18nLocaleObject(namespaceList.admin, "linking"), properties: [[linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" })]] },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "ratingStyle",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "ratingStyle"),
                                type: "checkbox",
                            },
                            {
                                variable: "ratingIconColor",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "ratingIconColor"),
                                type: "dual-color",
                                default: "icon-default",
                                visible: (options: WidgetOptions) => !!options.ratingStyle,
                            },
                            {
                                variable: "ratingIconSize",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "ratingIconSize"),
                                type: "number",
                                visible: (options: WidgetOptions) => !!options.ratingStyle,
                            },
                            {
                                variable: "reviewStyle",
                                label: getI18nLocaleObject(namespaceList.widgetResultsReviewRating, "reviewStyle"),
                                type: "checkbox",
                            },
                            {
                                variable: "descFontSize",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontSize"),
                                type: "select",
                                default: "default-font-size",
                                visible: (options: WidgetOptions) => !!options.reviewStyle,
                                optionList: [
                                    {
                                        value: "default-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                                    },
                                    {
                                        value: "micro-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                                    },
                                    {
                                        value: "xx-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                                    },
                                    {
                                        value: "x-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                                    },
                                    {
                                        value: "small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                                    },
                                    {
                                        value: "h6-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                                    },
                                    {
                                        value: "h5-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                                    },
                                    {
                                        value: "h4-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                                    },
                                    {
                                        value: "h3-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                                    },
                                    {
                                        value: "h2-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                                    },
                                    {
                                        value: "h1-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                                    },
                                ],
                            },
                            {
                                variable: "descFontColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "fontColor"),
                                type: "dual-color",
                                default: "font-default",
                                visible: (options: WidgetOptions) => !!options.reviewStyle,
                            },
                            {
                                variable: "hideTexts",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "hideTexts"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "review"),
                    visible: (options: WidgetOptions) => options.showReview!,
                    properties: [
                        [
                            localized({
                                variable: "localizedContent",
                                tabContent: [
                                    {
                                        type: "paragraph",
                                        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "infoToShowReviewOfSixMonths"),
                                    },
                                    {
                                        variable: "minRatingForReview",
                                        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "minRating"),
                                        type: "number",
                                        default: 7,
                                    },
                                    {
                                        variable: "categoryId",
                                        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "selectCategory"),
                                        type: "select",
                                        optionList: () => getQuestionnaireCategories(MxtsApi),
                                        required: false,
                                    },
                                    {
                                        variable: "commentId",
                                        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "selectComment"),
                                        type: "autocomplete",
                                        isClearable: false,
                                        options: (item, spec, root, tabLocale) => getComments(MxtsApi, root, tabLocale),
                                    },
                                ],
                            }),

                            {
                                variable: "showReplyFromResort",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showReplyFromResort"),
                                default: false,
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "rating"),
                    visible: (options: WidgetOptions) => options.showRating!,
                    properties: [
                        [
                            {
                                variable: "outOfFiveRating",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "outOfFiveRating"),
                                default: false,
                                type: "checkbox",
                                groupName: "singleRatingGroupOne",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleRatingGroupOneTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleRatingGroupOneDescription"),
                            },
                            {
                                variable: "minRating",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "minRating"),
                                type: "number",
                                groupName: "singleRatingGroupOne",
                            },
                            {
                                variable: "showNumberOfReview",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showNumberOfReview"),
                                default: false,
                                type: "checkbox",
                                groupName: "singleRatingGroupTwo",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleRatingGroupTwoTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleRatingGroupTwoDescription"),
                            },
                            {
                                variable: "showInGoogleAggregation",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showInGoogleAggregation"),
                                default: false,
                                type: "checkbox",
                                groupName: "singleRatingGroupTwo",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "extra"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoDescription"),
                            },
                            {
                                variable: "webContentId",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "noReviewFoundWebcontent"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                                groupName: "singleReviewRatingExtraGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleReviewRatingExtraGroupTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleReviewRatingExtraGroupDescription"),
                            },
                            {
                                variable: "templateId",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "noReviewFoundTemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                                groupName: "singleReviewRatingExtraGroup",
                            },
                            {
                                variable: "showNotEnoughRatingsText",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showNotEnoughRatingsText"),
                                default: false,
                                type: "checkbox",
                                groupName: "singleReviewRatingExtraGroupTwo",
                                groupTitle: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleReviewRatingExtraGroupTwoTitle"),
                                groupDescription: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleReviewRatingExtraGroupTwoDescription"),
                            },
                            {
                                variable: "notEnoughRatingsText",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "showNotEnoughRatingsText"),
                                type: "text",
                                groupName: "singleReviewRatingExtraGroupTwo",
                                visible: (options: WidgetOptions) => !!options.showNotEnoughRatingsText,
                            },
                            {
                                variable: "lessReviewWebContentId",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "lessReviewFoundWebcontent"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                                groupName: "singleReviewRatingExtraGroupTwo",
                                visible: (options: WidgetOptions) => !!options.showNotEnoughRatingsText,
                            },
                            {
                                variable: "lessReviewTemplateId",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "lessReviewFoundTemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                                groupName: "singleReviewRatingExtraGroupTwo",
                                visible: (options: WidgetOptions) => !!options.showNotEnoughRatingsText,
                            },
                            {
                                variable: "displayLessReviewInfo",
                                label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "displayLessReviewInfo"),
                                type: "select",
                                default: "viewAsInline",
                                groupName: "singleReviewRatingExtraGroupTwo",
                                visible: (options: WidgetOptions) => !!options.showNotEnoughRatingsText,
                                optionList: [
                                    {
                                        value: "viewAsInline",
                                        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "viewAsInline"),
                                    },
                                    {
                                        value: "viewAsTooltip",
                                        label: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "viewAsTooltip"),
                                    },
                                    {
                                        value: "viewAsModal",
                                        label: getI18nLocaleObject(namespaceList.admin, "viewAsModal"),
                                    },
                                ],
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const singleReviewRatingWidget: PageWidgetSpec<WidgetOptions> = {
    id: "SingleReviewRatingWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleReviewRating"),
    description: getI18nLocaleObject(namespaceList.dynamicSingleReviewRating, "singleReviewRatingDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        localizedContent: [],
        ratingStyle: false,
        descFontSize: "default-font-size",
        descFontColor: "font-default",
        ratingIconColor: "icon-default",
        reviewStyle: false,
        webContentId: null,
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const { linking } = widget.options;
        const { options } = widget;
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, options.styleIds || []);
        const link: ConfiguredLink = await getLinkFromLinkingSpec({ linkingSpecOptions: linking, context });
        return <DynamicSingleReviewRating dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} link={link} className={styleClasses} />;
    },
};
