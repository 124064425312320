import * as React from "react";
import * as classNames from "classnames";

import { AccommodationType, getMxtsEnv } from "../../mxts";
import { WebContent as CmsApiWebContent, Site, WithId } from "@maxxton/cms-api";
import { ConfiguredLink, getUrlWithAnchor } from "../../../utils/linking.util";
import { Content, getContent, isContentFilterChanged } from "../../../utils/content.util";
import { DESCRIPTION, DESCRIPTION2, NAME, RESORT, SHORT_DESCRIPTION, getHideWidgetClass, getNoDataFoundContent, getNoDataFoundTemplate, isClientLoggedIn } from "../../../components/utils";
import { QuestionnaireStatistic as MXTQuestionnaireStatistic, PagedResult, QuestionAnswer, Resort, Resource, Unit } from "@maxxton/cms-mxts-api";
import RatingStars, { Iconstyles } from "../../../components/RatingStars";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { CurrentLocale } from "../../../app.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { NavLink } from "reactstrap";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { ReviewRatingFallback } from "../../../components/ReviewRatingFallback";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import namespaceList from "../../../i18n/namespaceList";
import { renderNoResultsFoundContent } from "../containerWidget.util";

interface SingleReviewRatingProps extends DynamicWidgetBaseProps<WidgetOptions>, SingleReviewRatingStoreProps {
    link: ConfiguredLink;
    className: string;
}

interface ContentDescriptionFields {
    dynamicFieldCode?: string;
    description2?: string;
    shortDescription?: string;
    description?: string;
    name?: string;
}

interface SingleReviewRatingState {
    questionnaireStatistic?: MXTQuestionnaireStatistic;
    questionAnswer?: QuestionAnswer;
    disableWidget: boolean;
    contentImage: string;
    contentDescriptionFields: ContentDescriptionFields;
    fallbackTemplate: JSX.Element[] | null;
    fallbackWebContent: (CmsApiWebContent & WithId) | null;
}

interface SingleReviewRatingStoreProps {
    myEnvState: MyEnvState;
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
}

class SingleReviewRatingWidget extends React.Component<SingleReviewRatingProps, SingleReviewRatingState> {
    constructor(props: SingleReviewRatingProps) {
        super(props);
        this.state = {
            disableWidget: true,
            contentImage: "",
            contentDescriptionFields: {},
            fallbackTemplate: null,
            fallbackWebContent: null,
        };
    }

    public componentDidMount() {
        this.loadContent();
        this.setState({ disableWidget: !isClientLoggedIn() });
    }

    public componentDidUpdate(prevProps: SingleReviewRatingProps) {
        if (isContentFilterChanged(prevProps, this.props)) {
            this.loadContent();
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            context: { currentLocale, site },
            options,
            link,
            className,
        } = this.props;
        const { fallbackTemplate, fallbackWebContent } = this.state;
        const { contentDescriptionFields } = this.state;
        const { questionnaireStatistic, questionAnswer, contentImage, disableWidget } = this.state;
        const hideWidget = getHideWidgetClass(options, disableWidget);
        if (fallbackTemplate || fallbackWebContent) {
            const { context } = this.props;
            return <div className="fallback-review-rating">{renderNoResultsFoundContent({ noResultsFoundWebContent: fallbackWebContent, noResultsFoundTemplate: fallbackTemplate, context })}</div>;
        } else if (!questionnaireStatistic?.rating) {
            return null;
        }

        if (options.showNotEnoughRatingsText && questionnaireStatistic.questionnaireCount && questionnaireStatistic.questionnaireCount < 4 && options.displayLessReviewInfo) {
            return <ReviewRatingFallback reviewProps={this.props} />;
        }
        // jscpd:ignore-start
        if (hideWidget === null) {
            return null;
        }

        const dynamicFontStyleClass = `${options.descFontColor?.includes("theme") ? `color-${options.descFontColor}` : ""}`;
        const styles: any = {
            color: options.descFontColor?.includes("rgba") && options.reviewStyle ? options.descFontColor : undefined,
        };
        const iconstyles: Iconstyles = {
            fill: options.ratingIconColor?.includes("rgba") ? options.ratingIconColor : undefined,
            stroke: options.ratingIconColor?.includes("rgba") ? options.ratingIconColor : undefined,
            stopColor: options.ratingIconColor?.includes("rgba") ? options.ratingIconColor : undefined,
        };
        // jscpd:ignore-end
        let schemaCode;
        if (contentImage || contentDescriptionFields.description || contentDescriptionFields.name) {
            // eslint-disable-next-line max-len
            schemaCode = `{"image": "${contentImage}", "@context": "http://schema.org", "name": "${contentDescriptionFields.name}", "aggregateRating": {"reviewCount" : ${
                (questionnaireStatistic && questionnaireStatistic.questionnaireCount) || ""
            }, "ratingValue" : ${(questionnaireStatistic && questionnaireStatistic.rating / 2) || ""}, "@type" : "AggregateRating", "bestRating" : 5}, "@type" : "Hotel", "description" : "${
                contentDescriptionFields.description
            }"}`;
        }
        const reviewRatingParams = {
            options,
            questionnaireStatistic,
            contentDescriptionFields,
            iconstyles,
            schemaCode,
            styles,
            dynamicFontStyleClass,
            currentLocale,
            site,
            className,
            link,
        };
        return (
            <div className={classNames("single-review-rating", hideWidget, { ["values"]: options.hideTexts })}>
                {questionnaireStatistic &&
                    (link.url ? (
                        <SmartLink href={getUrlWithAnchor(link)} target={link.target}>
                            {singleReviewRatingContent(reviewRatingParams)}
                        </SmartLink>
                    ) : (
                        singleReviewRatingContent(reviewRatingParams)
                    ))}
                {questionAnswer && (
                    <div className="review-comment space-mb-s">
                        <div className="client-comment">{questionAnswer.comments}</div>
                        {options.showReplyFromResort && <div className="review-comment-reply">{questionAnswer.resortReply}</div>}
                    </div>
                )}
            </div>
        );
    }

    private async setNoReviewFallback({ options, context }: { options: WidgetOptions; context: CMSProviderProperties }) {
        const { webContentId, templateId } = options;

        if (templateId) {
            const fallbackTemplate = await getNoDataFoundTemplate(templateId, context);
            this.setState({ fallbackTemplate });
        }
        if (webContentId) {
            const fallbackWebContent = await getNoDataFoundContent(webContentId);
            this.setState({ fallbackWebContent });
        }
    }

    private loadContent = async () => {
        const { context, options, dynamicContainerOptions } = this.props;
        const [env, content] = await Promise.all([
            getMxtsEnv(context, context.currentLocale.code),
            getContent({ ...this.props, skipContentTypeSelectorCheck: true }) as Promise<Exclude<Content, Resort[]>>,
        ]);

        if (content) {
            const localized = options.localizedContent.find((lc) => lc.locale === context.site.locale._id);
            const imageManagerId = content.imageManagerId;
            const contentType = options.contentType || (dynamicContainerOptions && dynamicContainerOptions.contentType);
            const questionnaireStatsManagerId = (content as Unit | AccommodationType).questionnaireStatsManagerId || (content as Resort).questionaireStatsManagerId;
            this.setState({ contentDescriptionFields: this.getContentDescriptionFields(content as Unit | Resort | Resource | null) });

            if (contentType && options.showRating) {
                const dynamicImages = imageManagerId ? await context.mxtsApi.imagesPerManager(env, {}, [{ key: "imageManagerId", value: imageManagerId }]) : undefined;
                let image = "";
                if (dynamicImages?.[0]?.urls) {
                    image = dynamicImages[0].urls.original?.replace("t_newyse", "t_mcms");
                }
                if (questionnaireStatsManagerId) {
                    if (contentType === RESORT) {
                        const questStats = await context.mxtsApi
                            .questionnaireStatistics(env, {
                                managerId: questionnaireStatsManagerId,
                            })
                            .then((res: PagedResult<MXTQuestionnaireStatistic>) => res);
                        let ratingTotal = 0;
                        let totalQuestionnaireCount = 0;
                        questStats.content.forEach((review) => {
                            if (review) {
                                ratingTotal += review.questionnaireCount * review.rating;
                                totalQuestionnaireCount += review.questionnaireCount;
                            }
                        });
                        const calculatedRating = ratingTotal / totalQuestionnaireCount;
                        const reviewObject: MXTQuestionnaireStatistic = {
                            concernId: null,
                            generatedDate: null,
                            managerId: questionnaireStatsManagerId,
                            name: null,
                            ownRating: false,
                            questionCategoryId: +"null",
                            questionnaireCount: totalQuestionnaireCount,
                            questionnaireStatisticsId: null,
                            rating: Math.abs(+(options.outOfFiveRating ? calculatedRating / 2 : calculatedRating).toFixed(1)),
                        } as any;
                        this.setState({ questionnaireStatistic: reviewObject, contentImage: image }, () => {
                            !reviewObject?.rating && this.setNoReviewFallback({ options, context });
                        });
                    } else {
                        const questStats = await context.mxtsApi
                            .questionnaireStatistics(env, {
                                managerId: questionnaireStatsManagerId,
                                questionCategoryId: "null",
                                minRating: options.minRating || 7,
                            })
                            .then((res: PagedResult<MXTQuestionnaireStatistic>) => res.content);
                        const questionnaireStatistic = questStats && questStats[0] ? { ...questStats[0] } : undefined;
                        if (options.outOfFiveRating && questionnaireStatistic) {
                            questionnaireStatistic.rating = +(questionnaireStatistic.rating / 2).toFixed(1);
                        }
                        this.setState({ questionnaireStatistic, contentImage: image }, () => {
                            !questionnaireStatistic?.rating && this.setNoReviewFallback({ options, context });
                        });
                    }
                }
            }

            if (contentType && options.showReview && localized?.categoryId && localized.commentId) {
                const questionAnswer = await context.mxtsApi
                    .questionAnswers(env, {
                        questionnaireAnswerId: localized.commentId,
                    })
                    .then((res) => res.content[0]);
                this.setState({ questionAnswer });
            }
        }
    };

    private getContentDescriptionFields(resource: Unit | Resort | Resource | null): ContentDescriptionFields {
        const { options } = this.props;
        const contentDescriptionFields: ContentDescriptionFields = {};
        if (options?.descriptionTypes?.length) {
            options.descriptionTypes.forEach((type: NumberMultiSelectOption) => {
                switch (type.value) {
                    case NAME:
                        contentDescriptionFields.name = resource?.name || "";
                        break;
                    case DESCRIPTION:
                        contentDescriptionFields.description = resource?.description || "";
                        break;
                    case SHORT_DESCRIPTION:
                        contentDescriptionFields.shortDescription = resource?.shortDescription || "";
                        break;
                    case DESCRIPTION2:
                        contentDescriptionFields.description2 = (resource as any)?.description2 || "";
                        break;
                }
            });
        } else {
            contentDescriptionFields.name = resource?.name || "";
            contentDescriptionFields.description = resource?.description || "";
        }
        return contentDescriptionFields;
    }
}

function mapStateToProps(state: State): SingleReviewRatingStoreProps {
    return {
        myEnvState: state.myEnvState,
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
    };
}

function singleReviewRatingContent(params: {
    questionnaireStatistic?: MXTQuestionnaireStatistic;
    options: WidgetOptions;
    iconstyles: Iconstyles;
    styles: any;
    contentDescriptionFields: ContentDescriptionFields;
    schemaCode?: string;
    dynamicFontStyleClass: string;
    currentLocale: CurrentLocale;
    site: Site & WithId;
    className: string;
    link: ConfiguredLink;
}) {
    const { options, link, className, questionnaireStatistic, contentDescriptionFields, iconstyles, schemaCode, styles, dynamicFontStyleClass, currentLocale, site } = params;
    if (!questionnaireStatistic) {
        return null;
    }
    return (
        <div className={(classNames("review-rating space-mb-xs"), className)}>
            <RatingStars
                rating={questionnaireStatistic.rating}
                className="space-mr-xxs stars"
                maxRating={options.outOfFiveRating ? 5 : undefined}
                iconstyles={options.ratingStyle ? iconstyles : undefined}
                ratingIconSize={options.ratingStyle ? options.ratingIconSize : undefined}
            />
            {!!options.descriptionTypes?.length &&
                Object.keys(contentDescriptionFields)
                    .filter((fieldKey: keyof ContentDescriptionFields) => contentDescriptionFields[fieldKey])
                    .map((fieldKey: keyof ContentDescriptionFields) => (
                        <span key={fieldKey} className={`space-mr-xxs ${fieldKey}-field`}>
                            {contentDescriptionFields[fieldKey]}
                        </span>
                    ))}
            {!options.hideTexts && <span className="space-mr-xxs receive-text">{getI18nLocaleString(namespaceList.dynamicSingleReviewRating, "receive", currentLocale, site)}</span>}
            <span className={`font-weight-bold rate-value rating__text space-mr-xxs ${options.descFontSize || ""} ${dynamicFontStyleClass}`} style={styles}>
                {!options.hideTexts ? `${questionnaireStatistic.rating}/${options.outOfFiveRating ? 5 : 10}` : questionnaireStatistic.rating}
            </span>
            {options.showNumberOfReview && !link.anchor?.length && (
                <span className="review-rating__review">
                    {!options.hideTexts && (
                        <span className="review-rating__review-basedon space-mr-xxs">{getI18nLocaleString(namespaceList.dynamicSingleReviewRating, "basedon", currentLocale, site)}</span>
                    )}
                    <span className="review-rating__review-amount rating__text space-mr-xxs">{questionnaireStatistic.questionnaireCount}</span>
                    <span className="review-rating__review-text space-mr-xxs">{getI18nLocaleString(namespaceList.dynamicSingleReviewRating, "singleReviewPeoplesReviews", currentLocale, site)}</span>
                </span>
            )}
            {options.showNumberOfReview && link.anchor?.length && (
                <NavLink href={getUrlWithAnchor(link) || undefined} smooth="true">
                    {!options.hideTexts && (
                        <span className="review-rating__review-basedon space-mr-xxs">{getI18nLocaleString(namespaceList.dynamicSingleReviewRating, "basedon", currentLocale, site)}</span>
                    )}
                    <span className="review-rating__review-amount rating__text space-mr-xxs">{questionnaireStatistic.questionnaireCount}</span>
                    <span className="review-rating__review-text space-mr-xxs">{getI18nLocaleString(namespaceList.dynamicSingleReviewRating, "singleReviewPeoplesReviews", currentLocale, site)}</span>
                </NavLink>
            )}
            {options.showInGoogleAggregation && schemaCode && (
                <div className="google-rating">
                    <script type="application/ld+json">{schemaCode}</script>
                </div>
            )}
        </div>
    );
}

const SingleReviewRating = connect<SingleReviewRatingStoreProps>(mapStateToProps)(SingleReviewRatingWidget);

export const DynamicSingleReviewRating = wrapProps<DynamicWidgetBaseProps<WidgetOptions>>(SingleReviewRating);
