import * as FontAwesome from "react-fontawesome";
import * as React from "react";

import { WebContent as CmsApiWebContent, WithId } from "@maxxton/cms-api";
import { Modal, ModalBody, ModalHeader, Tooltip } from "reactstrap";
import { getNoDataFoundContent, getNoDataFoundTemplate } from "./utils";

import { renderNoResultsFoundContent } from "../plugins/dynamic/containerWidget.util";

enum viewOptions {
    viewAsModal = "viewAsModal",
    viewAsInline = "viewAsInline",
}

interface ReviewRatingFallbackState {
    lessReviewWebContent: (CmsApiWebContent & WithId) | null;
    lessReviewTemplate: JSX.Element[] | null;
    isModalOpen: boolean;
    isToolTipOpen: boolean;
}

export function ReviewRatingFallback({ reviewProps }: any) {
    const [fallback, setFallback] = React.useState<ReviewRatingFallbackState>({
        lessReviewWebContent: null,
        lessReviewTemplate: null,
        isModalOpen: false,
        isToolTipOpen: false,
    });

    React.useEffect(() => {
        setLessReviewFallback();
    }, []);

    const handleLessReviewInfo = () => {
        const { options } = reviewProps;
        const { isModalOpen } = fallback;
        if (options.displayLessReviewInfo === viewOptions.viewAsModal) {
            setFallback({ ...fallback, isModalOpen: !isModalOpen });
        }
    };
    const toggleReviewToolTip = () => {
        const { isToolTipOpen } = fallback;
        setFallback({ ...fallback, isToolTipOpen: !isToolTipOpen });
    };

    const getLessReviewModal = () => {
        const { lessReviewWebContent, lessReviewTemplate, isModalOpen } = fallback;
        const { context } = reviewProps;
        return (
            <Modal isOpen={isModalOpen} toggle={handleLessReviewInfo} size="s" className={"result-panel-button help-modal special-modal no-review"}>
                <ModalHeader toggle={handleLessReviewInfo}></ModalHeader>
                <ModalBody>
                    <div className="fallback-review-rating">{renderNoResultsFoundContent({ noResultsFoundWebContent: lessReviewWebContent, noResultsFoundTemplate: lessReviewTemplate, context })}</div>
                </ModalBody>
            </Modal>
        );
    };
    const getLessReviewTooltip = () => {
        const { lessReviewWebContent, isToolTipOpen } = fallback;
        const { context } = reviewProps;
        return (
            <Tooltip className="review_tooltip" arrowClassName="review_arrow" placement="bottom-start" isOpen={isToolTipOpen} target="TooltipId" toggle={toggleReviewToolTip}>
                <div className="fallback-review-rating">{renderNoResultsFoundContent({ noResultsFoundWebContent: lessReviewWebContent, noResultsFoundTemplate: null, context })}</div>
            </Tooltip>
        );
    };

    const setLessReviewFallback = async () => {
        const { options, context } = reviewProps;
        const { lessReviewTemplateId, lessReviewWebContentId } = options;
        if (lessReviewTemplateId) {
            const lessReviewTemplate = await getNoDataFoundTemplate(lessReviewTemplateId, context);
            setFallback({ ...fallback, lessReviewTemplate });
        }
        if (lessReviewWebContentId) {
            const lessReviewWebContent = await getNoDataFoundContent(lessReviewWebContentId);
            setFallback({ ...fallback, lessReviewWebContent });
        }
    };

    return (
        <div className="less-review-rating">
            <span>{reviewProps.options.notEnoughRatingsText}</span>
            <div className="display-review-info" onClick={reviewProps.options.displayLessReviewInfo !== viewOptions.viewAsInline ? handleLessReviewInfo : undefined}>
                <FontAwesome name="question-circle" id="TooltipId" />
                {reviewProps.options.displayLessReviewInfo !== viewOptions.viewAsInline &&
                    (reviewProps.options.displayLessReviewInfo === viewOptions.viewAsModal ? getLessReviewModal() : getLessReviewTooltip())}
            </div>
        </div>
    );
}
